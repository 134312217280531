<template>
  <router-view />
</template>

<script>
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { mapGetters } from 'vuex'

export default {
  name: 'MainRoot',
  data() {
    return {
      // isLoading: true,
      // user: {},
    };
  },
  computed: {
    ...mapGetters([
      'loggedMember', 
      'loggedUser',
      // 'communityinitialized',
      // 'mainCollective',
      // 'currentCollective',
      // 'collectiveFavicon'
    ]),
    
    communitySlug() {
      return this.$route.params.communityId;
    },
    // mainSpace() {
    //   return this.currentCollective?.parentKey ? this.mainCollective : this.currentCollective;
    // }
  },

  async created() {
    // console.log('Starting MainRoot...');
    // console.log('MainRoot.vue', this.$route)
    // Start Application
    // this.isLoading = true;
    // console.log('this.loggedUser', this.loggedUser);
    // console.log('this.loggedMember', this.loggedMember);
    // console.log('MainRoot - this.communitySlug', this.communitySlug);
    const typeOfCommunitySlug = typeof this.communitySlug;  
    let dontUseSlug = false;
    if(typeOfCommunitySlug === 'string'){
      // If community slug is "error" redirect to main root
      if (this.communitySlug === 'error' || this.communitySlug === 'undefined') {
        // console.log('SpaceRoot - Error is not a valid community slug, redirecting to main root... ');
        this.$router.push('/');
        dontUseSlug = true;
      }
    } else if(typeOfCommunitySlug === 'object'){
      // If community slug is an object, it means that the community slug is a community
      console.log('typeOfCommunitySlug is object!');
      dontUseSlug = true;
    }
    console.log('MainRoot - this.communitySlug', this.communitySlug);

    if(!this.loggedMember.id) {
      console.log('Member not logged in')
      const isBackoffice = this.$route.path.includes('/backoffice');
      // console.log('MainRoot.vue 2')
      console.log('dispatch fetchLoggedUser2 this.communitySlug:', this.communitySlug)
      await this.$store.dispatch('fetchLoggedUser2', {
        communitySlug: dontUseSlug ? null : this.communitySlug,
        isBackoffice: isBackoffice
      });
    }

    // this.isLoading = false;
    // console.log('FINAL MainRoot.vue')
  },

  mounted() {
    if (!this.$store.state.copernicData.mountedSocket[this.communitySlug]) {
      this.$socket.emit('joinAnalytics', this.communitySlug);
      this.$store.state.copernicData.mountedSocket[this.communitySlug] = true;
    }
    if (!this.$store.state.copernicData.mountedSocket.global) {
      this.notifications();
      this.$store.state.copernicData.mountedSocket.global = true;
    }
  },

  methods: {
    notifications() {
      // 
    },
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },

  },
};
</script>
